/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./Header.scss";

function Header() {
  const [active, setActive] = useState(1);
  return (
    <div className="header-pinnacle">
      <span
        className={`menu-item ${active === 1 ? "active" : ""}`}
        onClick={() => {
          setActive(1);
          window.scrollTo({
            top: document.getElementById("project-synopsis").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Project Synopsis
      </span>
      <span
        className={`menu-item ${active === 2 ? "active" : ""}`}
        onClick={() => {
          setActive(2);
          window.scrollTo({
            top: document.getElementById("location").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Location
      </span>
      <span
        className={`menu-item ${active === 3 ? "active" : ""}`}
        onClick={() => {
          setActive(3);
          window.scrollTo({
            top:
              document.getElementById("project-specifications").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Project Specifications
      </span>
      <span
        className={`menu-item ${active === 4 ? "active" : ""}`}
        onClick={() => {
          setActive(4);
          window.scrollTo({
            top: document.getElementById("floor-plans").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Floor Plans
      </span>
      <span
        className={`menu-item ${active === 5 ? "active" : ""}`}
        onClick={() => {
          setActive(5);
          window.scrollTo({
            top: document.getElementById("gallery").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Gallery
      </span>
      <span
        className={`menu-item ${active === 6 ? "active" : ""}`}
        onClick={() => {
          setActive(6);
          window.scrollTo({
            top: document.getElementById("project-status").offsetTop - 80,
            behavior: "smooth",
          });
        }}
      >
        Project Status
      </span>
    </div>
  );
}

export default Header;
