/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./HeroSection.scss";
import heroSectionCover from "../../../images/pinnacle/Cover/hero-section-cover.webp";
import pinnacleLogo from "../../../images/pinnacle/Cover/Logo/pinnacle-logo.png";
import PdfModal from "./PdfModal";
import reraPdfUrl from "../../../images/pinnacle/RERA-letter.jpg";

function HeroSection() {
  const [showPdfModal, setShowPdfModal] = useState(false);
  return (
    <>
      <section className="heroSection">
        <div className="rera-warning" onClick={() => setShowPdfModal(true)}>
          *This project does not come under RERA, Click to view the certificate.
        </div>
        <img
          src={heroSectionCover}
          alt="Hero Section Cover Image"
          className="heroSectionCover"
          loading="eager"
        />
        <div className="overlay"></div>
        <div className="heroContent">
          <div className="logo">
            <img
              src={pinnacleLogo}
              className="pinnacleLogo"
              alt="The Pinnacle Logo"
              loading="eager"
            />
          </div>
          <div className="heroText">
            <h1 className="heroTextHeadingWeb">
              Homes with
              <br />
              unrestricted character
              <br />
              and unhurried tranquillity
            </h1>
            <h1 className="heroTextHeadingMobile">
              Homes with unrestricted character and unhurried tranquillity
            </h1>
          </div>
        </div>
      </section>
      <PdfModal
        showPdfModal={showPdfModal}
        setShowPdfModal={setShowPdfModal}
        pdfUrl={reraPdfUrl}
      />
    </>
  );
}

export default HeroSection;
