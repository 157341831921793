/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./FloorPlans.scss";
import floorPlanImg from "../../../images/pinnacle/Image/Floor-Plans.webp";
import axios from "axios";

function FloorPlans() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSubmit = async (e) => {
    // setShowSuccessModal(true);
    e.preventDefault();
    // Handle form submission logic here
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.email === ""
    ) {
      return;
    }

    try {
      let response = await axios.post(
        "https://emailer.billiontags.com/bashyam/",
        {
          email: formData.email,
          name: formData.name,
          number: formData.phone,
          type: "Enquiry",
        }
      );

      if (response.status === 200) {
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 4000);
        setFormData({
          name: "",
          phone: "",
          email: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="floor-plans" id="floor-plans">
      <div className="floor-plans__content">
        <div className="floor-plans__content__text">
          <h1>Floor Plans</h1>
          <p>
            Kindly share your contact details. Our team will get <br /> back to
            you with the floor plans
          </p>
        </div>
        <div className="floor-plans__content__form">
          <form onSubmit={handleSubmit} className="floor-plans__form">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </form>
          <div
            className={`user-form-success-modal-web ${
              showSuccessModal
                ? "show fade-in-section-sucess-modal"
                : "hide fade-out-section-sucess-modal"
            }`}
          >
            <div>Thank you for enquiring about our prestigious project. </div>
            <div>Our Product Specialist will contact you shortly.</div>
          </div>
        </div>
      </div>
      <div className="floor-plans__image">
        <img src={floorPlanImg} alt="Floor Plans" />
      </div>
    </div>
  );
}

export default FloorPlans;
