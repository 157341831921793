/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./ImageModal.scss";
import { LeftOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Img from "gatsby-image";
function ImageModal({
  imagesArray,
  selectedImage,
  showModal,
  setShowModal,
  setSelectedImage,
}) {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [addSlideAnimate, setAddSlideAnimate] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(selectedImage);
  const lastTouchX = useRef(0);
  const currentTouchX = useRef(0);
  const imageSwiped = useRef(false);

  useEffect(() => {
    setSelectedImageIndex(selectedImage);
    setShowRightArrow(true);
    setShowLeftArrow(true);

    if (selectedImage === 1) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
    }
    if (selectedImage === imagesArray.length) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (showModal) {
      let timeout = setTimeout(() => {
        setAddSlideAnimate(true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      setSelectedImage(null);
    };
  }, []);

  const slideLeft = () => {
    let newSelectedImageIndex = selectedImageIndex - 1;
    setSelectedImageIndex(newSelectedImageIndex);
    setShowRightArrow(true);
    if (newSelectedImageIndex > 1) {
      setShowLeftArrow(true);
    } else {
      setShowLeftArrow(false);
    }
  };

  const slideRight = () => {
    let newSelectedImageIndex = selectedImageIndex + 1;
    setSelectedImageIndex(newSelectedImageIndex);
    setShowLeftArrow(true);
    if (newSelectedImageIndex < imagesArray.length) {
      setShowRightArrow(true);
    } else {
      setShowRightArrow(false);
    }
  };

  const swipeLeft = () => {
    if (imageSwiped.current) return;
    imageSwiped.current = true;
    if (selectedImageIndex === 1) return;
    let newSelectedImageIndex = selectedImageIndex - 1;
    setSelectedImageIndex(newSelectedImageIndex);
  };

  const swipeRight = () => {
    if (imageSwiped.current) return;
    imageSwiped.current = true;
    if (selectedImageIndex === imagesArray.length) return;
    let newSelectedImageIndex = selectedImageIndex + 1;
    setSelectedImageIndex(newSelectedImageIndex);
  };

  return (
    <Modal
      closable
      styles={{ mask: { background: "rgba(0, 0, 0, 0.65)" } }}
      className="image-modal-web-pinnacle"
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <div
        onTouchStart={(e) => {
          lastTouchX.current = e.touches[0].clientX;
        }}
        onTouchMove={(e) => {
          currentTouchX.current = e.touches[0].clientX;
          const touchDiffX = currentTouchX.current - lastTouchX.current;
          const touchDiffY = Math.abs(
            e.touches[0].clientY - e.touches[0].clientY
          );

          if (Math.abs(touchDiffX) > 20 && touchDiffY < 10) {
            if (touchDiffX > 0) {
              swipeLeft();
            } else {
              swipeRight();
            }
          }
        }}
        onTouchEnd={() => {
          imageSwiped.current = false;
        }}
        className={`carousel-container ${addSlideAnimate ? "set-animate" : ""}`}
      >
        {imagesArray?.map((image, index) => (
          <div
            key={index}
            className={`carousel_item`}
            style={{
              transform: `translateX(-${(selectedImageIndex - 1) * 100}%)`,
            }}
          >
            <Img
              alt=""
              fluid={
                image?.childImageSharp?.fluid ||
                image?.src.childImageSharp?.fluid
              }
            />
          </div>
        ))}
        <div className="pagination">
          <span>{selectedImageIndex}</span>/<span>{imagesArray?.length}</span>
        </div>
        {showLeftArrow ? <LeftOutlined onClick={() => slideLeft()} /> : <></>}
        {showRightArrow ? (
          <RightOutlined onClick={() => slideRight()} />
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}

export default ImageModal;
