/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./Gallery.scss";
import Img from "gatsby-image";
import PinnacleData from "./PinnacleData";
import pillarHeadline from "../../../images/pinnacle/Image/heading-pillar-2.png";
import ImageModal from "./ImageModal";

function Gallery() {
  const [activeTab, setActiveTab] = useState("interior");
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const projectData = PinnacleData();

  const interiorImages = projectData?.[0]?.sections?.[0]?.gallery?.[1]?.images?.map(
    (item) => ({
      src: item,
      alt: "gallery image",
    })
  );

  const exteriorImages = projectData?.[0]?.sections?.[0]?.gallery?.[0]?.images?.map(
    (item) => ({
      src: item,
      alt: "gallery image",
    })
  );

  return (
    <>
      <div className="gallery" id="gallery">
        <div className="gallery-header">
          <h1>
            <img
              src={pillarHeadline}
              alt="pillar-headline"
              className="pillar-headline"
            />
            Gallery
          </h1>
          <div className="tabs">
            <button
              className={`tab ${activeTab === "interior" ? "active" : ""}`}
              onClick={() => setActiveTab("interior")}
            >
              Interior
            </button>
            <button
              className={`tab ${activeTab === "exterior" ? "active" : ""}`}
              onClick={() => setActiveTab("exterior")}
            >
              Exterior
            </button>
          </div>
        </div>

        <div className="gallery-grid">
          {(activeTab === "interior" ? interiorImages : exteriorImages).map(
            (image, index) => (
              <div
                className="gallery-item"
                key={index}
                onClick={() => {
                  setSelectedGalleryImage(index + 1);
                  setShowGalleryModal(true);
                }}
              >
                <Img
                  src={image.src}
                  alt={image.alt}
                  fluid={image.src.childImageSharp.fluid}
                />
              </div>
            )
          )}
        </div>
      </div>

      {showGalleryModal ? (
        <ImageModal
          imagesArray={
            activeTab === "interior" ? interiorImages : exteriorImages
          }
          selectedImage={selectedGalleryImage}
          setSelectedImage={setSelectedGalleryImage}
          showModal={showGalleryModal}
          setShowModal={setShowGalleryModal}
        />
      ) : null}
    </>
  );
}

export default Gallery;
