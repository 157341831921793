/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ProjectSpecifications.scss";
import { RightOutlined } from "@ant-design/icons";
import buildingImg from "../../../images/pinnacle/Image/5-Building.png";
import pillarHeadline from "../../../images/pinnacle/Image/heading-pillar-2.png";

function ProjectSpecifications() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      className="project-specifications-container"
      id="project-specifications"
    >
      <h1>
        <img
          src={pillarHeadline}
          alt="pillar-headline"
          className="pillar-headline"
        />
        Project Specifications
      </h1>
      <div className="project-specifications-content">
        <div className="accordion-container">
          {[
            {
              header: "Structure",
              list: [
                "RCC frame structure with masonry partitions",
                "Internal masonry - Aerocon blocks or equivalent",
              ],
            },
            {
              header: "Floor Finishes",
              list: [
                "Living: Imported Marble laid",
                "Kitchen: Granite flooring",
                "Bedrooms: Imported Marble",
                "Sit-Out: Granite matte flooring",
                "Utility: 2x2 Vitrified Tiles of a reputed brand",
                "Toilets: Imported Marble up to false ceiling height in all toilets",
                "Powder Room: Imported Marble",
              ],
            },
            {
              header: "Wall Finishes",
              list: [
                "Internal walls & ceiling: Smooth putty finish with 2 coats of emulsion paint of reputed brand over one coat of primer",
                "External walls: Stone/Textured finish and 2 coats of exterior emulsion paint of reputed brand over one coat of primer",
              ],
            },
            {
              header: "Sanitary & Plumbing",
              list: [
                "Bedroom toilets: Western Style, Porcelain EWC of Catalano/Equivalent",
                "Wash basin: Porcelain wash basins of Catalano/Equivalent",
                "Bathrooms: Provided with shower cubicle plus rain shower and hand shower",
                "Overhead showers : Will be of Fantini/equivalent in all bathrooms",
                "Master bedrooms: Will have Rain shower",
                "Faucets : All faucets shall be CP, colour-coordinated heavy body metal fittings of Fantini/equivalent",
                "Hot water connection shall be provided to shower, wash basin, bathrooms and kitchen sink.",
                "Plumbing: All external water supply lines & drainage lines shall be in PVC pipes",
                "Internal water supply lines will be of concealed CPVC Pipes",
              ],
            },
            {
              header: "Doors",
              list: [
                "Main door: Teakwood frame doors with wooden veneer shutters. The main door shall have a height of 8 feet",
                "Other doors: Wooden frame and shutters with natural wood veneer finish",
                "Bedroom doors: Shall have a height of 8 feet",
                "Internal door finish: Internal door shutters and frames shall be provided with a melamine matt finish",
              ],
            },
            {
              header: "Windows",
              list: ["Aluminium windows with mosquito mesh"],
            },
            {
              header: "Electrical Wiring & Switches",
              list: [
                "3-Phase supply with independent meters and phase change facility in each apartment",
                "Concealed electrical wiring with Finolex Cables or equivalent and sufficient points with Schneider or equivalent modular switches",
                "False ceiling with LED lights provided",
                "Home automation in Living and Dining Area and all Bedrooms",
                "Data points and CAT 6 cable for all bedrooms and Living Area with networking option",
                "Heat pump for all apartments",
                "Automation system with touchscreen in the Foyer",
                "6-button touch panel in the Living and Dining area",
                "A handy 4-button smart panel by the bedside",
              ],
            },
            {
              header: "Home Automation System",
              additionalFeatures: [
                "Main Door Intrusion detection",
                "Bathroom lighting fitted with motion sensors",
                "All Bedroom air-conditioning with on/off Control",
                "Fully automated air-conditioning for Living/Dining area",
                "Gas leak Sensor",
                "Curtain control",
              ],
              list: [],
            },
            {
              header: "Power Backup",
              list: [
                "Backup power provided to the entire unit and common area lighting, pumps, motors, etc",
                "Generators have acoustic enclosures and an AMF panel with an automatic change-over switch",
              ],
            },
            {
              header: "Water Plant",
              list: [
                "A water purification plant provided for the supply of treated water to the units",
                "Rainwater Harvesting: Percolation pits are provided for the same",
              ],
            },
            {
              header: "Elevators / Lifts",
              list: [
                "Mitsubishi passenger elevators shall be provided with automatic doors",
              ],
            },
            {
              header: "Common Amenities and Facilities",
              subPoints: [
                {
                  title: "Security System:",
                  list: [
                    "Security cameras at the Main gate, Elevators, Servant rooms, Ground floor lobby area, and all common areas including Gymnasium",
                    "Intercom facility connecting each apartment to the security room",
                  ],
                },
                {
                  title: "Other Facilities / Amenities:",
                  list: [
                    "Gymnasium, Swimming pool",
                    "Integrated DTH system using TRIAX",
                  ],
                },
              ],
            },
          ].map((item, index) => (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-header ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <RightOutlined className="arrow" />
                {item.header}
              </div>
              <ul
                className={`accordion-content ${item?.additionalFeatures
                  ?.length && "additional-features"} ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                {item?.list?.length
                  ? item?.list?.map((listItem, index) => (
                      <li key={index}>{listItem}</li>
                    ))
                  : ""}
                {item?.additionalFeatures?.length ? (
                  <li>Additional Features:</li>
                ) : (
                  ""
                )}
                {item?.additionalFeatures?.length
                  ? item?.additionalFeatures?.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))
                  : ""}
                {item?.subPoints?.length
                  ? item?.subPoints?.map((subPoint, index) => (
                      <>
                        <li key={index} className="sub-point-title">
                          {subPoint.title}
                        </li>
                        {subPoint.list?.map((listItem, index) => (
                          <li key={index} className="sub-point-list">
                            {listItem}
                          </li>
                        ))}
                      </>
                    ))
                  : ""}
              </ul>
            </div>
          ))}
        </div>
        <div className="building-image-container">
          <img src={buildingImg} alt="Building" />
        </div>
      </div>
    </div>
  );
}

export default ProjectSpecifications;
