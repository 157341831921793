/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./ProjectSynopsis.scss";
import projectSynopsisImage1 from "../../../images/pinnacle/Image/1-Common-Terrace-01.webp";
import projectSynopsisImage2 from "../../../images/pinnacle/Image/2-Entrance-Drop-Off.webp";
import projectSynopsisImage3 from "../../../images/pinnacle/Image/3-Swimming-Pool.webp";
import pillarHeadline from "../../../images/pinnacle/Image/heading-pillar-2.png";

function ProjectSynopsis() {
  const [activeImage, setActiveImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveImage((prev) => (prev + 1) % 3);
      setIsTransitioning(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [activeImage]);

  return (
    <div className="project-synopsis" id="project-synopsis">
      <div className="project-synopsis-container">
        <div className="project-synopsis-content">
          <h1>
            <img
              src={pillarHeadline}
              alt="pillar-headline"
              className="pillar-headline"
            />
            Project Synopsis
          </h1>
          <p>
            This sets the tone for residents to voluntarily prioritise spending
            quality time with family at this wholesomely comforting home. This
            will be their bedrock and soulful place of refuge.
          </p>
        </div>
        <div className="project-synopsis-image-flex-container">
          <div className="project-synopsis-body-container">
            <div className="project-synopsis-body">
              <h2>
                An honest intent to promote a harmonious, luxurious and
                sustainable lifestyle
              </h2>
              <p>
                We were unabashedly committed to promoting a sustainable
                lifestyle that deftly melds verdant greens with diligent
                planning and supreme design intent.
              </p>
              <p>
                The façade sets the tone with an in-vogue yet pared back appeal
                with stylish projections clad in exquisite stone. Deft rounded
                edges and curves add charm to the demure design sophistication.
              </p>

              <div className="project-synopsis-body-2">
                <h2>Doubly attractive Sustainability measures</h2>
                <p>
                  Besides promising to be eye-catching architecture, this
                  building exudes a modern design language and tonality that is
                  also sustainable from a performance point of view.
                </p>
                <p>
                  The building&apos;s longer façade has a North-South direction
                  orientation. This ensures ample, passive sunlight without
                  heat.
                </p>
                <p>
                  The East and West facades with decks act as makeshift buffer
                  spaces. This ensures the tropical heat is filtered out and
                  cool air gains entry.
                </p>
                <p>
                  Exotic gardens that engulf the periphery are the perfect foil
                  for lush green environs around.
                </p>
              </div>
            </div>
          </div>
          <div className="project-synopsis-image-container">
            <img
              style={{
                position: "absolute",
                transition: "all 0.8s linear",
                zIndex: activeImage === 0 ? 1 : 0,
                opacity: activeImage === 0 ? 1 : 0,
              }}
              src={projectSynopsisImage1}
              alt="Project Synopsis"
              loading="eager"
            />
            <img
              style={{
                position: "absolute",
                transition: "all 0.8s linear",
                zIndex: activeImage === 1 ? 1 : 0,
                opacity: activeImage === 1 ? 1 : 0,
              }}
              src={projectSynopsisImage2}
              alt="Project Synopsis"
              loading="eager"
            />
            <img
              style={{
                position: "absolute",
                transition: "all 0.8s linear",
                zIndex: activeImage === 2 ? 1 : 0,
                opacity: activeImage === 2 ? 1 : 0,
              }}
              src={projectSynopsisImage3}
              alt="Project Synopsis"
              loading="eager"
            />
          </div>
        </div>
        <div className="project-synopsis-body-3">
          <h2>Doubly attractive Sustainability measures</h2>
          <p>
            Besides promising to be eye-catching architecture, this building
            exudes a modern design language and tonality that is also
            sustainable from a performance point of view.
          </p>
          <p>
            The building&apos;s longer façade has a North-South direction
            orientation. This ensures ample, passive sunlight without heat.
          </p>
          <p>
            The East and West facades with decks act as makeshift buffer spaces.
            This ensures the tropical heat is filtered out and cool air gains
            entry.
          </p>
          <p>
            Exotic gardens that engulf the periphery are the perfect foil for
            lush green environs around.
          </p>
        </div>
      </div>
    </div>
  );
}
export default ProjectSynopsis;
