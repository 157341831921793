/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import "./Challenges.scss";

function Challenges() {
  const challengesRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // if (challengesRef.current && typeof window !== undefined) {
      if (challengesRef.current) {
        const rect = challengesRef.current.getBoundingClientRect();
        console.log(rect.top, window.innerHeight - 100, "rect.top");
        if (rect.top <= window.innerHeight - 100) {
          setIsInView(true);
        }
      }
    });
  }, []);

  return (
    <div
      className={`challenges ${isInView ? "in-view" : ""}`}
      ref={challengesRef}
      id="challenges"
    >
      <h2 className="challenge-heading">
        The challenge was to add fresh character to the plush neighbourhood and
        build a shining testament that evokes awe
      </h2>
      <div className="challenge-content">
        <div className="challenge-content-col-1">
          <div className="challenge-content-col-1-line">
            <span className="challenge-content-col-1-line-circle"></span>
          </div>
          <span className="challenge-content-col-1-line-text">
            How do you pay newer accolades to feted neighbourhoods in the city?
          </span>
        </div>
        <div className="challenge-content-col-2">
          <div className="challenge-content-col-2-line">
            <span className="challenge-content-col-2-line-circle"></span>
          </div>
          <span className="challenge-content-col-2-line-text">
            How do you surprise and impress illustrious, resident Billionaires
            who have seen it all?
          </span>
        </div>
        <div className="challenge-content-col-3">
          <div className="challenge-content-col-3-line">
            <span className="challenge-content-col-3-line-circle"></span>
          </div>
          <span className="challenge-content-col-3-line-text">
            How can a new residence be grander and outrank its neighbours in
            stature?
          </span>
        </div>
        <div className="challenge-content-col-4">
          <div className="challenge-content-col-4-line">
            <span className="challenge-content-col-4-line-circle"></span>
          </div>
          <div className="challenge-content-col-4-text-container">
            <span className="challenge-content-col-4-line-text">
              How do you build homes that go beyond the eye-candy and become
              super sustainable?
            </span>
            <div className="challenge-content-col-5">
              When posed with these probing questions we earnestly went back to
              the drawing board and{" "}
              <span style={{ fontWeight: "600" }}>
                made a honest attempt to tackle these enquiries with dedicated
                intent, unmatched zeal and unrestrained flair.
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Challenges;
