/* eslint-disable no-unused-vars */
import React from "react";
import "../components/projects/pinnacle/styles.scss";
import HeroSection from "../components/projects/pinnacle/HeroSection.jsx";
import Header from "../components/projects/pinnacle/Header.jsx";
import ProjectSynopsis from "../components/projects/pinnacle/ProjectSynopsis.jsx";
import Location from "../components/projects/pinnacle/Location.jsx";
import ProjectSpecifications from "../components/projects/pinnacle/ProjectSpecifications.jsx";
import FloorPlans from "../components/projects/pinnacle/FloorPlans.jsx";
import Gallery from "../components/projects/pinnacle/Gallery.jsx";
import GalleryBanner from "../components/projects/pinnacle/GalleryBanner.jsx";
import ProjectStatus from "../components/projects/pinnacle/ProjectStatus.jsx";
import Challenges from "../components/projects/pinnacle/Challenges.jsx";
import Footer from "../components/common/Footer.js";
import Layout from "../components/common/Layout";

function Pinnacle() {
  return (
    <Layout from="Pinnacle">
      <HeroSection />
      <Header />
      <ProjectSynopsis />
      <GalleryBanner />
      <Challenges />
      <Location />
      <ProjectSpecifications />
      <FloorPlans />
      <Gallery />
      <ProjectStatus />
      <Footer />
    </Layout>
  );
}

export default Pinnacle;
