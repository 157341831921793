/* eslint-disable no-unused-vars */
import React from "react";
import "./Location.scss";
import pinnacleLogo from "../../../images/pinnacle/Cover/The-Pinnacle-Logo.png";
import pillarHeadline from "../../../images/pinnacle/Image/heading-pillar-2.png";

function Location() {
  return (
    <div className="location-container" id="location">
      <h1>
        <img
          src={pillarHeadline}
          alt="pillar-headline"
          className="pillar-headline"
        />
        Location
      </h1>
      <div className="google-maps-iframe-container">
        <iframe
          title="google map"
          // src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d62195.67219404264!2d80.19689844773345!3d13.021051374014863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x3a52672477d16659%3A0xcbe47c61442177!2sEmperors%20Pavilion%20-%20Baashyaam%20Constructions%2C%20Ferry%20Road%2C%20Chitra%20Nagar%2C%20Kotturpuram%2C%20Chennai%2C%20Tamil%20Nadu!3m2!1d13.020957699999999!2d80.2381391!4m0!5e0!3m2!1sen!2sin!4v1739992841592!5m2!1sen!2sin"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1325421254637!2d80.2512359!3d13.0272305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267ccb6593a77%3A0xcc8ab28b63ae2b78!2sThe%20Pinnacle%20-%20Baashyaam%20Constructions!5e0!3m2!1sen!2sin!4v1740643298065!5m2!1sen!2sin"
          // style={{ border: 0, width: "60vw", height: 255 }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="logo-container">
          <img alt="" src={pinnacleLogo} className="asset-1" />
          <span className="address-text">
            Plot No A, Old Door No.15 Part, Present Door No 8/1, Boat Club Road,
            Raja Annamalaipuram, <br />
            Chennai-600 028.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Location;
