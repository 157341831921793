/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import "./PdfModal.scss";

function PdfModal({ showPdfModal, setShowPdfModal, pdfUrl }) {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowPdfModal(false);
      setIsClosing(false);
    }, 300); // Match this with CSS animation duration
  };

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (showPdfModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showPdfModal]);

  if (!showPdfModal) return null;

  return (
    <div className="pdf-modal-overlay" onClick={handleClose}>
      <div
        className={`pdf-modal-content ${isClosing ? "closing" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="pdf-modal-close" onClick={handleClose}>
          <CloseOutlined />
        </button>
        <div className="pdf-modal-body">
          <img src={pdfUrl} alt="PDF" />
        </div>
      </div>
    </div>
  );
}

export default PdfModal;
