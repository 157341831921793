/* eslint-disable no-unused-vars */
import React from "react";
import "./GalleryBanner.scss";
import largePillar from "../../../images/pinnacle/Image/large-pillar.png";

function GalleryBanner() {
  return (
    <div className="gallery-banner" id="gallery-banner">
      <img src={largePillar} alt="Large Pillar" className="large-pillar-1" />
      <img src={largePillar} alt="Large Pillar" className="large-pillar-2" />
      <div className="gallery-banner-content">
        <div className="left-content">
          <p className="emulating">Emulating</p>
          <h2 className="villa-style">Villa-style living</h2>
          <p className="description">
            The Luxury Homes are built on an expansive plot set amidst abundant
            foliage
          </p>
        </div>
        <div className="right-content">
          <div className="main-box">
            <h2>6 LUXURIOUS APARTMENTS</h2>
            <div className="line"></div>
            <p>
              Almost{" "}
              <span style={{ fontWeight: "600" }}>40,000 square feet</span> of
              space{" "}
              <span style={{ fontWeight: "600" }}>spread across 5 levels</span>
            </p>
          </div>
          <div className="details">
            <div className="lavish">
              <h3>
                <span style={{ fontWeight: "600" }}>6 LAVISH 4-BHK</span>{" "}
                APARTMENTS
              </h3>
              <p>
                <span style={{ fontWeight: "600" }}>
                  spread across 3 levels
                </span>{" "}
                and spanning nearly{" "}
                <span style={{ fontWeight: "600" }}>
                  5,000 square feet each
                </span>
              </p>
            </div>
            <div className="penthouse">
              <h3>
                <span style={{ fontWeight: "600" }}>1 PREMIER PENTHOUSE</span>
              </h3>
              <p>
                Encompassing{" "}
                <span style={{ fontWeight: "600" }}>10,000 square feet</span> of
                opulent space
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryBanner;
